import React from 'react';


const KotlinLogo = () => {
    return (
        <div className="skills-container">
            <div className="skill">
                <img src="/kotlin-bw.svg" alt="Kotlin" />
           
            </div>
            
        </div>
    );
};

export default KotlinLogo;
